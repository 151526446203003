import {
        getTrafficDelays,
        getTrafficComplaints,
        getTrafficSnoozedDelays,
        addTrafficSnoozedDelay,
        getTrafficEmailsSent,
        getTrafficUsers,
        addTrafficDelayMeta,
        addTrafficDelayLog,
        delaysUnassign,
        createTrafficComplaint
      } from '@/services/apiTraffic';

import { parseISO, differenceInDays, format } from 'date-fns';


const state = () => ({
  delays: [],
  trafficUsers: [],
});

const getters = {
  getDelays: (state) => state.delays,
  getTrafficUsers: (state) => state.trafficUsers,
};


const actions = {
  async fetchAllDelays({ commit }) {

    const [
      trafficDelays,
      trafficComplaints,
      trafficSnoozedDelays,
      trafficEmailsSent
    ] = await Promise.all([
      getTrafficDelays(),
      getTrafficComplaints(),
      getTrafficSnoozedDelays(),
      getTrafficEmailsSent(),
    ])

    const snoozed = {};
    const dMeta = {
      assigned: '',
      supplierEmail: false,
      status: ''
    };

    // Create a map for complaints
    const complaints = new Map();
    trafficComplaints.forEach((o, i) => {
      complaints.set(o.id_order_detail, true);
    })

    // Create a map for snoozed delays
    trafficSnoozedDelays.forEach((o, i) => {
      snoozed[o.delay_id] = o.expires_at;
    });

    // Create a map for customer emails sent
    const customerEmailsMap = new Map();
    trafficEmailsSent.forEach(innerArray => {
      innerArray.forEach(obj => {
        const key = `${obj.customer_id}-${obj.order_id}`;
        customerEmailsMap.set(key, true);
      });
    });

    const allDelays = trafficDelays.map(delay => {
      const currentDate = new Date();
      const delayDate = parseISO(delay.despatch_date_target);
      delay.delayDuration = differenceInDays(currentDate, delayDate);

      const meta = delay.meta ?? dMeta;
      const complaint = complaints.has(parseInt(delay.id_order_detail));
      const isSnoozed = snoozed[delay.id_order_detail];
      const checkIfCustomerEmailSent = customerEmailsMap.has(`${delay.id_customer}-${delay.id_order}-${delay.id_order_detail}`);

      return {
        ...delay,
        ...meta,
        fullOrderNumber: `${delay.id_order}-${delay.id_order_detail}`,
        complaint: complaint,
        status: checkIfCustomerEmailSent ? 'Customer informed' : meta.status,
        customerEmailed: checkIfCustomerEmailSent,
        salesPrice: delay.totalPriceTaxIncl,
        shopUrl: delay.shop_name,
        customerPostCode: delay.postcode,
        snoozedTill: isSnoozed ? format(new Date(isSnoozed), 'dd-MM-yyyy hh:mm:ss') : undefined,
        snoozed: !!isSnoozed ? 'Yes' : 'No',
      };
    });

    commit('SET_DELAYS', allDelays);
  },
  async fetchTrafficUsers({ commit }) {
    const trafficUsers = await getTrafficUsers();
    commit('SET_TRAFFIC_USERS', trafficUsers);
  },
  async assignDelaysToUser({ commit, rootGetters }, payload) {
    const user = rootGetters['user'];
    const response = await addTrafficDelayMeta(user.idToken, payload);
    if(response.status === 200) {
      payload.orderDetailIds.forEach(async (delay_id) => {
        await addTrafficDelayLog({
          action: "Assigned to",
          value: payload.meta.assigned,
          delay_id,
          email: user.email
        });
        commit('UPDATE_DELAY_ASSIGNEE', { delayId: delay_id, assignee: payload.meta.assigned})
      })
    }
    return response;
  },
  async unassignDelays({ commit }) {
    const response = await delaysUnassign();
    if(response.status === 200) {
      commit('UNASSING_DELAYS');
    }
    return response;
  },
  async editDelayOrder({ commit, rootGetters }, payload) {
    const user = rootGetters['user'];
    const response = await addTrafficDelayMeta(payload);
    if(response.status === 200) {
      Object.keys(payload.meta).forEach(async (key) => {
        // delay_id, action, value, email
        await addTrafficDelayLog({
          delay_id: payload.orderDetailIds,
          action: getLogMetaDescription(key),
          value: payload.meta[key],
          email: user.email
        });
      })
      commit('UPDATE_DELAY_ORDER', { 
        delayId: payload.orderDetailIds,
        assignee: payload.meta.assigned,
        status: payload.meta.status,
        delayReason: payload.meta.delayReason,
        newTargetDispatch: payload.meta.newTargetDispatch,
      })
    }
    return response;
  },
  async snoozeDelay({ commit }, payload) {
    const response = await addTrafficSnoozedDelay(payload);
    commit('UPDATE_SNOOZED_DELAY_ORDER', payload.delay_id);
    return response;
  },
  async createComplaint({ commit, rootGetters }, payload) {
    const user = rootGetters['user'];
    const response = await createTrafficComplaint(payload);
    if(response.status === 200) {
      payload.orderDetailIds.forEach(async (delay_id) => {
        await addTrafficDelayLog({
          action: "Complained",
          value: payload.typeName,
          delay_id,
          email: user.email
        });
        await commit('SET_DELAY_COMPLAINT', delay_id);
      })
    }
    
    return response;
  },
  async setCustomerEmailSent({ commit }, payload) {
    commit('UPDATE_CUSTOMER_EMAIL_SENT', { delayId: payload.orderDetailId });
  }

};

// mutations
const mutations = {
  SET_DELAYS(state, delays) {
    state.delays = delays;
  },
  SET_TRAFFIC_USERS(state, trafficUsers) {
    state.trafficUsers = trafficUsers;
  },
  UNASSING_DELAYS(state) {
    state.delays.forEach((delay) => {
      delay.assigned = '';
    });
  },
  UPDATE_DELAY_ASSIGNEE(state, { delayId, assignee }) {
    const index = state.delays.findIndex((delay) => delay.id_order_detail === delayId);
    state.delays[index].assigned = assignee;
  },
  UPDATE_DELAY_ORDER(state, { delayId, assignee, status, delayReason, newTargetDispatch }) {
    const index = state.delays.findIndex((delay) => delay.id_order_detail === delayId);
    state.delays[index].assigned = assignee;
    state.delays[index].status = status;
    state.delays[index].delayReason = delayReason;
    state.delays[index].newTargetDispatch = newTargetDispatch;
  },
  UPDATE_CUSTOMER_EMAIL_SENT(state, { delayId }) {
    const index = state.delays.findIndex((delay) => delay.id_order_detail === delayId);
    state.delays[index].customerEmailed = true;
  },
  SET_DELAY_COMPLAINT(state, delayId) {
    const index = state.delays.findIndex((delay) => delay.id_order_detail === delayId);
    state.delays[index].complaint = true;
  },
  UPDATE_SNOOZED_DELAY_ORDER(state, delayId) {
    const index = state.delays.findIndex((delay) => delay.id_order_detail === delayId);
    state.delays[index].snoozed = 'Yes';
  }
};

// Helper function to split array into chunks
function chunkArray(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

function getLogMetaDescription(value) {
  const logMeta = {
    'assigned': "Assigned to",
    'delayReason': "Set Delay Reason as",
    'status': "Set Status as",
    'newTargetDispatch': "Set New Target Dispatch as",
    'comment': "Commented",
    'supplierEmail': "Emailed Supplier"
  }
  return logMeta[value];
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
