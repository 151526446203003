import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "@/store";
import HomeView from "@/views/HomeView.vue";
import ComplaintsSection from '@/components/decision-tree/ComplaintsSection.vue'
import Questions from '@/components/decision-tree/Questions.vue'
import Solutions from '@/components/decision-tree/Solutions.vue'
import SubComplaints from '@/components/decision-tree/SubComplaints.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      meta: {
        authNeeded: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
      path: "/customers",
      component: () => import("@/views/CustomersView.vue"),
      meta: { authNeeded: true },
      children: [
        {
          path: "",
          name: "customersView",
          component: () => import("@/components/customers/CustomersTable.vue"),
          meta: { authNeeded: true },
        },
        {
          path: ":id",
          name: "customerView",
          component: () => import("@/components/customers/Customer.vue")
        }
      ],
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("@/views/OrdersView.vue"),
      meta: { authNeeded: true },
      children: [
        {
          path: "",
          name: "ordersTable",
          component: () => import("@/components/orders/OrdersTable.vue"),
        },
        {
          path: ":id",
          name: "orderView",
          component: () => import("@/components/orders/Order.vue"),
        },
        {
          path: ":id/:orderlineId",
          name: "orderlineView",
          component: () => import("@/components/orders/orderline/Orderline.vue"),
        }
      ]
    },
    // {
    //   path: "/orders/:orderId",
    //   name: "order",
    //   component: () => import("@/views/OrderView.vue"),
    //   meta: { authNeeded: true },
    // },
    {
      path: "/helpcenter",
      name: "helpcenter",
      component: () => import("@/views/HelpcenterView.vue"),
      meta: { authNeeded: true },
    },
    {
      path: "/decision-tree",
      name: "decision-tree",
      component: () => import("@/views/DecisionTreeView.vue"),
      meta: { authNeeded: true },
      children: [
        {
          path: "",
          name: "ComplaintsSection",
          component: ComplaintsSection,
        },
        {
          path: "sub-complainst",
          name: "SubComplains",
          component: SubComplaints,
          props: (route) => ({
            subComplaintsParam: route.query.subComplaintsParam,
          }),
        },
        { 
          path: "questions/:id",
          name: "Questions",
          component: Questions 
        },
        {
          path: "solutions",
          name: "Solutions",
          component: Solutions,
          props: (route) => ({
            solutionInformation: route.query.solutionInformation,
          }),
        },
      ],
    },
    {
      path: "/quotes",
      name: "quotes",
      component: () => import("@/views/QuotesView.vue"),
      meta: { authNeeded: true },
      children: [
        {
          path: "",
          name: "quotesTable",
          component: () => import("@/components/quotes/QuotesTable.vue"),
          meta: { authNeeded: true },
        },
        {
          path: "newquote",
          name: "newquote",
          component: () => import("@/components/quotes/new/NewQuoteForm.vue"),
          meta: { authNeeded: true },
        },
        {
          path: "view/:quoteId",
          name: "quoteview",
          component: () => import("@/components/quotes/edit/EditQuoteForm.vue"),
          meta: { authNeeded: true },
        },
      ],
    },
    {
      path: "/traffic",
      name: "traffic",
      component: () => import("../views/TrafficView.vue"),
      meta: { authNeeded: true },
      children: [
        {
          path: "",
          name: "allDelays",
          component: () => import("@/components/traffic/AllDelaysTable.vue"),
          meta: { authNeeded: true },
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import("@/views/404.vue"),
      meta: { authNeeded: true },
    }
  ],
});

// const auth = getAuth();


function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
}

router.beforeEach(async (to) => {
  const authRequired = to.matched.some((record) => record.meta.authNeeded);
  const user = await getCurrentUser();
  if (authRequired && !user) {
    console.log("User not logged in, redirecting to login");
    return { path: "/login" };
  } else if (!authRequired && user) {
    const userData = {
      id: user.uid,
      name: user.displayName,
      email: user.email,
      photoUrl: user.photoURL || user.photoUrl,
      idToken: user.accessToken,
    };
    await store.dispatch("setUser", userData);
  }
});

export default router;
