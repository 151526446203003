import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import App from './App.vue'
import router from './router'
import { initializeApp } from 'firebase/app'
import * as Sentry from "@sentry/vue";

import store from './store' // or './store/index.js'

import '@/components/engaige/EngaigeBot.js'
import '@/scss/index.scss'

const firebaseConfig = {
  "apiKey": "AIzaSyBcfczmaR-DM411JYEcAbKMbpU8Q6pKk0c",
  "authDomain": "agents-dashboard-hp.firebaseapp.com",
  "projectId": "agents-dashboard-hp",
  "storageBucket": "agents-dashboard-hp.appspot.com",
  "messagingSenderId": "603709111567",
  "appId": "1:603709111567:web:4deb7dfacda5097f05d27a",
  "measurementId": "G-6HB7BX5V1S",
  "hostingUrl": "https://agents-dashboard-hp.web.app",
  "serviceAccountId": "firebase-adminsdk-4h0ox@agents-dashboard-hp.iam.gserviceaccount.com"
}

initializeApp(firebaseConfig)

const app = createApp(App)

app.use(ElementPlus)
app.use(router)
app.use(store)

Sentry.init({
  app,
  dsn: import.meta.env.SENTRY_DNS_CLIENT,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.mount('#app')

// window.markerConfig = {
//     project: '6515507d1833e966bbf2b7e8',
//     source: 'snippet'
// }

// // Load the Marker.io script
// const script = document.createElement('script')
// script.async = true
// script.src = "https://edge.marker.io/latest/shim.js"
// document.body.appendChild(script)